/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/layouts/index'),
    redirect: '/scientist',
    meta: {
      title: '工具',
      keepAlive: false
    },
    children: [
      {
        path: '/scientist',
        name: 'scientist',
        component: () => import('@/views/scientist'),
        meta: { title: 'scientist' }
      },
      {
        path: '/transfer',
        name: 'transfer',
        component: () => import('@/views/transfer'),
        meta: { title: 'transfer' }
      },
      {
        path: '/gas',
        name: 'gas',
        component: () => import('@/views/gas'),
        meta: { title: 'gas' }
      },
      {
        path: '/wallets',
        name: 'wallets',
        component: () => import('@/views/wallets'),
        meta: { title: 'wallets' }
      },
      // {
      //   path: '/token',
      //   name: 'token',
      //   component: () => import('@/views/token'),
      //   meta: { title: 'token' }
      // },
      {
        path: '/sale',
        name: 'sale',
        component: () => import('@/views/sale'),
        meta: { title: 'sale' }// 抢预售
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/order'),
        meta: { title: 'order' }// 挂单模式
      },
      {
        path: '/collect',
        name: 'collect',
        component: () => import('@/views/collect'),
        meta: { title: 'collect' }// 批量歸集錢包
      },
      {
        path: '/contract',
        name: 'contract',
        component: () => import('@/views/contract'),
        meta: { title: 'contract' }// 批量鏈上合約調用
      },
      {
        path: '/trade',
        name: 'trade',
        component: () => import('@/views/trade'),
        meta: { title: 'trade' }// 多地址批量自動交易
      },
      {
        path: '/address',
        name: 'address',
        component: () => import('@/views/address'),
        meta: { title: 'address' }// 靚號地址生成器
      }
    ]
  }
]
