import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function loadStores() {
  const locales = require.context('./modules/', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const obj = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      obj[locale] = locales(key).default
    }
  })
  return obj
}

const modules = loadStores();
const store = new Vuex.Store({
  modules: modules
})
export default store
