<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    document.body.addEventListener("touchstart", function() {}); 
  },
  methods: {},
};
</script>
<style lang="less">
.vm-message {
  background: #fff !important;
  border-radius: 6px 6px 6px 6px !important;
  border: 1px solid #E0E0E0!important;
  min-width: auto !important;
}

.vm-message__inner {
  margin: 0 !important;
  color: #2980ff !important;
}
.vm-message-icon--loading{
  color: #2980ff !important;
}
.ivu-tooltip{
  font-size: 0;
}
.ivu-tooltip-rel{
  display: flex !important;
  align-items: center;
}
</style>
