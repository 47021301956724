// import Vue from 'vue'
const app = {
  state: {
    showWin: false,
    showEndTime: '',
    isVip: false,

  },
  mutations: {
    SET_WIN: (state, win) => {
      state.showWin = win
    },
    SET_ENDTIME: (state, time) => {
      state.showEndTime = time
    },
    SET_VIP: (state, vip) => {
      state.isVip = vip
    },

  },
  actions: {
    setWin({
      commit
    }, lang) {
      commit('SET_WIN', lang)
    },
    setEndTime({
      commit
    }, time) {
      commit('SET_ENDTIME', time)
    },
    setVip({
      commit
    }, vip) {
      commit('SET_VIP', vip)
    },
  }
}
export default app