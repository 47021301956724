import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import VueI18n from 'vue-i18n'
Vue.use(VueI18n)


import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider)
Vue.config.productionTip = false

import { Tooltip, Switch, Drawer, Message, Modal, Table } from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.component('Tooltip', Tooltip);
Vue.component('ISwitch', Switch);
Vue.component('IDrawer', Drawer);
Vue.component('Table', Table);
Vue.component('Modal', Modal);

Vue.prototype.$Message = Message
import Message2 from 'vue-multiple-message'
Vue.prototype.$message = Message2
import './assets/pc/common.less'
import './assets/mobile/common.less'

import vuescroll from 'vuescroll/dist/vuescroll-native';

// 你可以在这里设置全局配置.
Vue.use(vuescroll, {
  ops: {
    // 设置默认的全局配置
    rail: {
      size: '0px'
    },
    bar: {
      size: '0px'
    },
  },
  name: "myScroll" // 自定义组件名称， 默认是vueScroll
});
const i18n = new VueI18n({
  locale: localStorage.lang || 'ch',
  messages: {
    'ch': require('./lang/ch'),
    'en': require('./lang/en'),
  }
})
new Vue({
  render: h => h(App),
  store,
  router,
  i18n,
}).$mount('#app')
