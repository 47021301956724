module.exports = {
    title: {
         scientist: 'Scientist rush mode',
         transfer: 'Multi-chain batch coin transfer',
         gas: 'Latest gas fee',
         wallets: 'Batch generate wallets<',
         sale: 'Grab the pre-sale',
         order: 'Pending order mode',
         collect: 'Batch collection of wallets',
         contract: 'Batch on-chain contract calls',
         trade: 'Multi-address batch automatic transaction',
         address: 'Pretty Number Address Generator',
     },
     title_head: {
         scientist: 'Scientist rush mode',
         transfer: 'Multi-chain batch coin transfer',
         gas: 'Latest gas fee',
         wallets: 'Batch generate wallets<',
         sale: 'Grab the pre-sale',
         order: 'Pending order mode',
         collect: 'Batch collection of wallets',
         contract: 'Batch on-chain contract calls',
         trade: 'Multi-address batch automatic transaction',
         address: 'Pretty Number Address Generator',
     },
    new:{
        1: 'Home',
        2: 'Advantage',
        3: 'Tools',
        tool: 'Tools',
        4: 'Token',
        5:'IDO',
        6: 'Mining',
        10: 'Making transactions easier and helping your assets is our eternal pursuit of service',
        11: 'Go to PancakeSwap to buy',
        12: 'View the candlestick chart',
        13: 'Build the safest home in the currency circle',
        14: 'Consultation Contact',
        15: 'Tool advantage',
        16: 'Using tools to consume tokens is extremely deflationary',
        17: 'Democratize tools and make transactions time-saving and worry-free',
        18: 'Breakthrough innovation, one-stop solution to the needs of people in the currency circle',
        19: 'The simplest and most practical tool in the currency circle',
        20: 'Tool Type',
        21: 'More tools are being updated continuously...',
        22: 'Scientist',
        23: 'Quick opening - step',
        24: 'Grab pre-sale',
        25: 'Get it when you grab it',
        26: 'Pending order',
        27: 'Farewell to the plate',
        28:'Gasoline query',
        29: 'Latest gas fee',
        30: 'Airdrop',
        31: 'One-click group sending',
        32: 'Token Filter',
        33: 'Good helper for profit',
        34: 'Navigation',
        35: 'Convenient Tools',
        36: 'Batch generation',
        37: 'Must-have wool',
        38: 'Token Issuance',
        39: 'name',
        40: 'Decimal point',
        41: 'Liquid Pot',
        42:'Liquidity mining',
        43: 'Type',
        44: 'Total supply',
        45: 'Ecological Development',
        46:'Buy and sell',
        47: 'Black Hole Destruction',
        48: 'LP Dividend',
        49: 'Auto Airdrop',
        50: 'Add/remove pool',
        51:'0.01% / five addresses',
        52: 'No slippage',
        53:'IDO pre-sale',
        54: 'Pre-sale',
        544: 'Over',
        55:'Total pre-sale $B-CAKE',
        56: 'Pre-sale places remaining',
        57:'To be claimed: ',
        58:'Subscribe $B-CAKE',
        588:'Receive $B-CAKE',
        59: 'Note: Each address can only subscribe once, a 100 tokens total 200U',
        60:'Invite friends to mine',
        61: '*Invite users to participate to get 3% of referral rewards directly to the account',
        62:'Invitation link: ',
        63:'LP Mining',
        64: 'liquidity',
        65:'Annualized interest rate',
        66: 'Enter the pledge amount',
        67: 'Available: ',
        68:'Pledged: ',
        69: 'Pledge',
        70: 'Full redemption',
        71: 'Earned',
        72: 'Withdraw',
        73: 'Add liquidity',
        74: 'Binding relationship',
        75: 'Contact',
        76: '*Pledge LP tokens to mine $B-CAKE and enjoy the dividends of LP',
        77: 'View Audit'




    },
    tool: {
         1: 'Vip exclusive features',
         2: 'Scientist snap-up',
         3: 'Grab pre-sale',
         4: 'Pending order',
         5: 'Multi-chain batch coins',
         6: 'Mass production wallet',
         7: 'latest gas fee',
         8: 'Buy VIP',
        9: 'Select public chain',
        10: 'Select Exchange',
        11: 'Node information',
        12: 'speed',
        13: 'Switch node',
        14: 'Please enter',
        15: 'Load',
        16: 'Wallet address',
        17: 'Please load the private key to get the address',
        18: 'Load key',
        19: 'Load key',
        20: 'This program runs completely locally and will not upload the private key to the server. Supervision and detection are welcome.',
        21: 'balance',
        22: 'Expiration time:',
        23: 'It is strongly recommended to use a small wallet with small assets to grab coins. The need for a private key is to save the confirmation operation and directly realize fast and automatic trading',
        24: 'Buy',
        25: 'Sell',
        26: 'Transaction currency',
        27: 'Flow cell',
        28: 'Automatically recognized',
        29: 'Flow cell',
        30: 'Contract address',
        31: 'Please enter the contract address',
        32: 'Confirm',
        3232: 'Pre Approve',
        33: 'quantity',
        34: 'Please enter the transaction quantity',
        35: 'Number of times',
        36: 'Please enter {0} times, the default is 1',
        37: 'Pending order price',
        38: 'Please enter the pending order price',
        39: 'Set Market Price',
        40: 'Current price',
        41: 'Please enter contract information',
        42: 'For example: you set the transaction quantity as 2BNB and the number of purchases as 5 times, then the scientist will automatically buy 2BNB continuously, buy 5 times, - buy a total of 10BNB',
        422: 'For example: you set the transaction quantity as 2BNB and the number of purchases as 5 times, then the scientist will automatically buy 2BNB continuously, buy 5 times, - buy a total of 10BNB',
        43: 'Buying Speed',
        44: 'Default gas cost {0} ≈ {1} (minimum reserved about {2} )',
        45: 'Failed to retry',
        46: 'Buy/sell failure will retry infinitely, please be careful',
        47: 'Rotation interval (milliseconds)',
        48: 'Price monitoring rotation training time, adjust according to your own network',
        49: 'Hash check',
        50: 'Do you want to check whether the purchase is successful after the order is placed? It will take a few more seconds to open it',
        51: 'Advanced features',
        52: 'Burn max percentage',
        53: 'The maximum burning percentage is 20% by default. It is recommended to fill in required fields to avoid losses caused by excessive slippage',
        1152: 'Burn max percentage',
        1153: 'The maximum burning percentage is 20% by default. It is recommended to fill in required fields to avoid losses caused by excessive slippage',
        54: 'Execute now',
        55: 'Pause snapping',
        56: 'Purchasing',
        57: 'Please enter the private key to import the wallet',
        58: 'VIP use only',
        59: 'Please enter the transaction quantity',
        60: 'Please enter the token contract address and click the OK button',
        61: 'Please enter the pending order price',
        62: 'Successful transaction',
        63: 'Authorization...',
        64: 'authorized',
        65: 'Please connect to BSC chain wallet to buy VIP',
        66: 'Please enter the node address',
        67: 'Please enter the correct node address',
        68: 'Permanent Member',
        69: 'Membership not purchased',
        70: 'The transaction fails if the transaction is not completed within a few seconds after the transaction is sent. It is recommended to set the interval 20-60',
         71: "Delay for a period of time before placing an order to prevent the order from being placed quickly",
         72: "The flow pool is monitored in real time and adjusted according to its own network.",
         73: "Unlimited burning in Gas mode requires the most sufficient handling fee",
     },
     vip: {
         1: 'Join VIP',
         2: ' days',
         3: 'Estimated payment:',
         4: 'Buy it now',
         5: 'Balance:',
         6: 'Please connect to BSC chain wallet to buy VIP',
         7: 'Please select the membership level to purchase',
         8: '',
         9: '',
         10: '',
         11: '',
         12: '',
         13: '',
     },
    lang: {
        5: 'Language',
        fail: 'Failed to receive',
         success: 'Received successfully',
        
         fail1: 'Failed',
        success1: 'Succeed',

        err1: 'The superior has been bound',
         err2: 'There is a subordinate, can no longer bind the superior',
         err22: 'It has been pledged and cannot be bound to the superior',

         err3: 'Please set the superior address',
         err4: 'Cannot bind itself',
         confirm: 'confirm',
         buy: 'bought',
         input: 'Enter the address binding relationship',
         nodata: 'No lower-level purchases yet',
         nodata2: 'No address to purchase yet',

         pause: 'has not started',
         balanceNotEnough: 'Insufficient balance',

         a1: 'Purchase pause',
		a2: 'Participated',
		a3: ' Insufficient balance',
		a4: 'Approval failed',
        a5: 'No.1 is not over yet',
        notStart: "Has Not Started",

        remainAmount: 'Insufficient pre-sale quota',
        min: 'Minimum purchase',
        max: 'Most purchased',

    },
    buy: {
        1: 'Minimum number of flow cells（USDT）',
        2: 'Do not fill in the default 0, no detection',
        3: 'amount',
        4: 'Please enter the purchase amount',
        5: 'Number of times',
        6: 'Monitor (milliseconds)',
        7: 'Buy expired (seconds)',
        8: 'Delay order (milliseconds)',
        9: 'Burning Gas Mode',
        10: 'Minimum available quantity',
        11: "Please enter the minimum available quantity, the default is 0",
        12: "Example: Suppose the opening price of the coin is 1U, you use 10U to buy 10 coins, if the minimum quantity is filled in 15, the transaction will be rolled back because the purchase quantity does not reach the minimum quantity",
        13: "price increases",
        14: "Price increase, default 0%",
        15: "Sell ratio",
        16: "Sell ratio, default 0%",
        17: "Interval time (milliseconds)",
    },
    sale: {
        1: 'If the purchase fails, it will be retried infinitely, so be careful',
        2: 'Do you want to check whether the purchase is successful after the order is placed? It will take a few more seconds to open it',
        3: 'Furious mode ignores the order status, you need to set the number of seconds to place an order in advance, please keep enough handling fee for burning gas',
        4: 'berserk mode',
        5: '',
    }
}