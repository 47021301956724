// import Vue from 'vue'
import axios from 'axios'
const model = {
  state: {
    connect: false,
    account: '',
    netVersion: '',
    chainId: '',
    web3: undefined,
    contract: {},
    balance: {
      BYB:0,BCG:0,DMC:0,GOLD:0,BNX:0
    }
  },
  mutations: {
    // 是否连接
    SET_WALLET_CONNECT: (state, data) => {
      state.connect = data
    },
    // 钱包账户
    SET_WALLET_ACCOUNT: (state, data) => {
      state.account = data
    },
    // 网络版本
    SET_WALLET_NET_VERSION: (state, data) => {
      state.netVersion = data
    },
    // 链ID
    SET_WALLET_CHAIN_ID: (state, data) => {
      state.chainId = data
    },
    // web3对象
    SET_WALLET_WEB3: (state, data) => {
      state.web3 = data
    },
    // contract合约对象
    SET_WALLET_CONTRACT: (state, data) => {
      console.log("SET_WALLET_CONTRACT", data)
      state.contract[data.key] = data.contract
    },
    // contract合约对象
    SET_WALLET_BALANCE: (state, data) => {
      state.balance[data.key] = data.balance
    },
    // getBalanceBYB:(state,data)=>{
    //   state.balance.BYB = state.balance.BYB -data
    // },
    // getBalanceBCG:(state,data)=>{
    //   state.balance.BCG = state.balance.BCG -data
    // },
  },
  actions: {
    /**
     * 查询余额
     * @param data 对象：{contract: 合约名称, symbol: 币种名称}
     * @returns {Promise<unknown>}
     */
    balanceOf({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        console.log(contract)
        contract.methods.balanceOf(data.addres).call().then(res => {
          console.log(res)
          resolve(res)
         
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 查询余额
     * @param data 对象：{contract: 合约名称, symbol: 币种名称}
     * @returns {Promise<unknown>}
     */
    balance({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        contract.methods.balanceOf(contract.options.address).call().then(res => {
          let balance = state.web3.utils.fromWei(res, 'ether')
          console.log('获取余额', '币种：' + data.symbol + '，数量：' + balance)
          commit('SET_WALLET_BALANCE', {key: data.symbol, balance: balance})
          resolve(balance)
        }).catch(err => {
          reject(err)
        })
      })
    },
    tokenURI({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        contract.methods.tokenURI(data.id).call().then(res => {
          axios.get(res).then(function(res){
            // arr=res
            var one=JSON.parse(localStorage.getItem("url")) 
            one.push(res)
            localStorage.setItem("url",JSON.stringify(one))
            // console.log(res.id)
            // commit('SET_URI_ADDRES', {addres: res})
          })
          
         
        }).catch(err => {
              localStorage.setItem("loading","false")
          reject(err)
        })
      })
      
    },
    batchBalance({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        contract.methods.batchBalance(data.addres).call().then(res => {
          localStorage.setItem("NFT_list",JSON.stringify(res))
          console.log(res)
          // axios.get(res[1]).then(function(res){
          //   // arr=res
          //   var one=JSON.parse(localStorage.getItem("NFT_list")) 
          //   one.push(res)
          //   localStorage.setItem("NFT_list",JSON.stringify(one))
          //   // console.log(res.id)
          //   // commit('SET_URI_ADDRES', {addres: res})
          // })
          
        }).catch(err => {
              localStorage.setItem("loading","false")
          reject(err)
        })
      })
      
    },
    /**
     * 合约授权。只有当从用户账户中扣除代币时调用。
     * @param data 对象：{from: 来源合约, to: 被授权合约}。说明：“被授权合约”可以从“来源合约”中当前账户地址提取代币
     * @returns {Promise<unknown>}
     */
    approve({state}, data) {
      return new Promise((resolve, reject) => {
        let contractFrom = state.contract[data.from]
        let contractTo = state.contract[data.to]
        //判断是否授权
       
        contractFrom.methods.allowance(state.account, contractTo.options.address).call().then(r => {
          if (r <= 1) {
           
            console.log('合约授权，From：' + data.from, '，To：' + data.to + '，账户：' + state.account)
            contractFrom.methods.approve(contractTo.options.address,"90000000000000000000000000000000000000000000000000000000000000000000000000000").send().then(() => {
              resolve()
            }).catch(err => {
              reject(err)
            })
          } else {
            resolve()
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    //工作授权
    isApprovedForAll({state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        //判断是否授权
        console.log(contract)
        contract.methods.isApprovedForAll(data.user_addres,data.addres).call().then(r => {
          console.log(r)
          if (!r) {
              contract.methods.setApprovalForAll("0xB02e07320317A58f6cea2c502c1FE9e38C547dA3",true).send().then((res) => {
                localStorage.setItem("work_lock","go")
              resolve()
            }).catch(err => {
              reject(err)
              localStorage.setItem("loading","false")
            })
          } else {
            localStorage.setItem("work_lock","go")
            resolve()
          }
        }).catch(err => {
          localStorage.setItem("loading","false")
          reject(err)
        })
      })
    },
    //查询正在工作的NFT
    userNftLength({commit, state}, data) {
      localStorage.setItem("all_work_num","")
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]

        contract.methods.userNftLength(data.user_addres,data.id).call().then(res => {
          console.log(res)
          localStorage.setItem("all_work_num",res)
          
          console.log(res)
        }).catch(err => {
              localStorage.setItem("loading","false")
          reject(err)
        })
      })
      
    },

    
      //查询用户信息
      getUserInfo({commit, state}, data) {
        return new Promise((resolve, reject) => {
          let contract = state.contract[data.contract]
          contract.methods.getUserInfo(data.addres).call().then(res => {
            console.log(res)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
        
      },
       //查询当前捐赠BNB
       getBalance({commit, state}, data) {
        return new Promise((resolve, reject) => {
          let contract = state.contract[data.contract]
          console.log("getBalance", contract.methods)
          contract.methods.balanceOf(data.addres).call().then(res => {
            console.log(res)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
        
      },
       getETHBalance({commit, state}, data) {
        return new Promise((resolve, reject) => {
          
          state.web3.eth.getBalance(data.addres).then(res => {
            console.log(res)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
        
      },
      //查询预售信息
      saleMap({commit, state}, data) {
        localStorage.setItem("sellPizzaAmount",0)
        return new Promise((resolve, reject) => {
          let contract = state.contract[data.contract]
         
          contract.methods.saleMap().call().then(res => {
            console.log(res)
            //售出的
            localStorage.setItem("sellPizzaAmount",res[7]/1e18)
           
            //预售的
            localStorage.setItem("pizzaTotalAmount",res[1]/1e18)
            
          }).catch(err => {
            reject(err)
          })
        })
        
      },
       //查询可提取pizza
       unpaid({commit, state}, data) {
        localStorage.setItem("sellPizzaAmount",0)
        return new Promise((resolve, reject) => {
          let contract = state.contract[data.contract]
          contract.methods.unpaid(data.user_addres).call().then(res => {
            console.log(res)
            localStorage.setItem("get_pizza",res)
            
          }).catch(err => {
            reject(err)
          })
        })
        
      },
    //大户查询正在工作的NFT的id
    userWork({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        contract.methods.userWork(data.user_addres,data.id,data.index).call().then(res => {
            var arr=res
            var one=JSON.parse(localStorage.getItem("big_work_id"))
            one.push(arr)
            console.log(one)
            localStorage.setItem("big_work_id",JSON.stringify(one))
        }).catch(err => {
              localStorage.setItem("loading","false")
          reject(err)
        })
      })
      
    },
      //大户根据id查询NFT的信息
      nftMap({commit, state}, data) {
        return new Promise((resolve, reject) => {
          let contract = state.contract[data.contract]
          contract.methods.nftMap(data.nft_id).call().then(res => {
            var arr=res
              var one=JSON.parse(localStorage.getItem("all_work_id"))
              arr.id=data.nft_id
              one.push(arr)
              localStorage.setItem("all_work_id",JSON.stringify(one))
              console.log(one)
          }).catch(err => {
                localStorage.setItem("loading","false")
            reject(err)
          })
        })
        
      },
    //根据下标查询nftid
    userNftForIndex({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        console.log(data.id,data.index)
        contract.methods.userNftForIndex(data.user_addres,data.id,data.index).call().then(res => {
            var arr=res
            console.log(res)
            var one=JSON.parse(localStorage.getItem("all_work_id"))
            one.push(arr)
            console.log(one)
            localStorage.setItem("all_work_id",JSON.stringify(one))
        }).catch(err => {
              localStorage.setItem("loading","false")
          reject(err)
        })
      })
      
    },
        //查询正在工作的NFT
        userNft({commit, state}, data) {
          return new Promise((resolve, reject) => {
            let contract = state.contract.WORK
            contract.methods.userNft(data.user_addres,data.work_id).call().then(res => {
              localStorage.setItem("work_list",JSON.stringify(res))
              console.log(res)
              // axios.get(res[1]).then(function(res){
              //   // arr=res
              //   var one=JSON.parse(localStorage.getItem("NFT_list")) 
              //   one.push(res)
              //   localStorage.setItem("NFT_list",JSON.stringify(one))
              //   // console.log(res.id)
              //   // commit('SET_URI_ADDRES', {addres: res})
              // })
              
            }).catch(err => {
                  localStorage.setItem("loading","false")
              reject(err)
            })
          })
          
        },
        //获取开启盲盒总次数
        maxNumOfDay({commit, state}, data) {
          return new Promise((resolve, reject) => {
            let contract = state.contract[data.contract]
            contract.methods.maxNumOfDay().call().then(res => {
              console.log(res)
              localStorage.setItem("maxNum",res)
              
              
            }).catch(err => {
                  localStorage.setItem("loading","false")
              reject(err)
            })
          })
        
      },
      //获取用户开启盲盒总次数
      numToDayTimes({commit, state}, data) {
        return new Promise((resolve, reject) => {
          let contract = state.contract[data.contract]
          contract.methods.numToDayTimes(data.addres).call().then(res => {
            localStorage.setItem("user_num",res)
            
          }).catch(err => {
                localStorage.setItem("loading","false")
            reject(err)
          })
        })
      
    },
    //获取邀请列表
    relation({commit, state}, data) {
      return new Promise((resolve, reject) => {
        let contract = state.contract[data.contract]
        contract.methods.relation(data.addres,data.id).call().then(res => {
          localStorage.setItem("invitation_list",JSON.stringify(res[1]))
          localStorage.setItem("invitation_money",res[2])
        }).catch(err => {
              localStorage.setItem("loading","false")
          reject(err)
        })
      })
    
  },
   //获取邀请列表
   getTokensByOwner({commit, state}, data) {
    return new Promise((resolve, reject) => {
      let contract = state.contract[data.contract]
      contract.methods.getTokensByOwner(data.addres).call().then(res => {
         resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  
},
  //usdt转账
  transfer({commit, state}, data) {
    var self=this
    return new Promise((resolve, reject) => {
      let contractFrom = state.contract[data.from]
      let contractTo = state.contract[data.to]
      let addres = data.addres
      let amount = String(data.amount)
      let addres_one=data.addres_one
      console.log("transfer",amount)
      contractFrom.methods.transfer(addres_one,amount).send({from:addres}).then((e) => {
        resolve(e)
      }).catch(err => {
        localStorage.setItem("loading","false")
      reject(err)
      })
    })
  },
  }
}
export default model
