module.exports = {
    title: {
        scientist: '科學家搶購模式',
        transfer: '多鏈批量打幣',
        gas: '最新汽油費',
        wallets: '批量生成錢包',
        sale: '搶預售',
        order: '掛單模式',
        collect: '批量歸集錢包',
        contract: '批量鏈上合約調用',
        trade: '多地址批量自動交易',
        address: '靚號地址生成器',
    },
    title_head: {
        scientist: '科學家搶購模式',
        transfer: '多鏈批量打幣',
        gas: '最新汽油費',
        wallets: '批量生成錢包',
        sale: '搶預售',
        order: '掛單模式',
        collect: '批量歸集錢包',
        contract: '批量鏈上合約調用',
        trade: '多地址批量自動交易(刷交易量)',
        address: '靚號地址生成器',
    },
    new:{
        1:'首页',
        2:'优势',
        3:'工具',
        tool: '前往工具',
        4:'代币',
        5:'IDO',
        6:'挖矿',
        10:'让交易变得更简单，从此告别通宵熬夜盯盘，为您的资产助力是我们永远不懈的服务追求',
        11:'前往 PancakeSwap 购买',
        12:'查看k线图',
        13:'打造币圈人最安心的家',
        14:'咨询联系',
        15:'工具优势',
        16:'以工具消耗代币极致通缩',
        17:'让工具大众化、让交易变得省时省力又省心',
        18:'突破性的创新，一站式解决币圈人交易的需求',
        19:'币圈最简单最实用的工具',
        20:'工具类型',
        21:'🛠️更多工具持续更新中...',
        22:'科学家',
        23:'开盘快人—步',
        24:'抢预售',
        25:'抢到即赚到',
        26:'挂单模式',
        27:'告别盯盘',
        28:'汽油查询',
        29:'全链最新gas费',
        30:'多链批量空投',
        31:'一键群发代币',
        32:'代币筛选器',
        33:'盈利好帮手',
        34:'区块链导航',
        35:'便捷工具',
        36:'批量生成地址',
        37:'薅羊毛必备',
        38:'代币发行',
        39:'名称',
        40:'小数点',
        41:'流动性底池',
        42:'流动性挖矿',
        43:'类型',
        44:'总供应量',
        45:'生态发展',
        46:'买入卖出',
        47:'黑洞销毁',
        48:'LP分红',
        49:'自动空投',
        50:'加/撤池子',
        51:'0.01% / 五个地址',
        52:'无滑点',
        53:'IDO预售',
        54:'预售中',
        544:'已结束',
        55:'总预售 $B-CAKE',
        56:'预售名额剩余',
        57:'待领取: ',
        58:'认购 $B-CAKE',
        588:'领取 $B-CAKE',
        59:'注: 每个地址只能认购一次，一份 100 枚代币共200U',
        60:'邀请好友挖矿',
        61:'*邀请用户参与可以获得3%的推荐奖励直接到账',
        62:'邀请链接: ',
        63:'LP 挖矿',
        64:'流动性',
        65:'年化利率',
        66:'输入质押数量',
        67:'可用: ',
        68:'已质押: ',
        69:'质押',
        70:'全部赎回',
        71:'已赚取',
        72:'提取收益',
        73: '前往添加流动性',
        74: '绑定关系',
        75: '咨询联系',
        76: '*质押LP代币可以挖矿$B-CAKE同时享受分红LP',
        77: '查看审计'




    },
    scientist: {
        1: '設置買入金額為2{0}，買入次數為5次，則科學家會自動連續購買2{0}，買5次,一共買10{0}',
        2: '延遲下單(毫秒)',
        3: '下單前進行延遲一段時間,防止下單快的情況',
        4: 'Hash檢查',
        5: '下單完成後是否要檢查本次購買是否成功，開啟則需要多花幾秒鐘時間',
        6: '監控(毫秒)',
        7: '流動池實時監控,根據自己網絡調整',
        8: '買入失效(秒)',
        9: '交易發出後幾秒內未成交就交易失敗,建議設置區間20-60秒',
        10: '允許購買到代幣最低數量，如果購買到數量低於該值則交易回滾（避免高位接盤代幣）',
        11: '燒Gas模式持續消耗手續費，需要預留足夠的手續費',
        12: '間隔時間(毫秒)',
        13: '當價格上漲條件百分比，默認0%',
        14: '若達到上漲條件賣出百分比，默認0%',
        15: '燒Gas模式下不可用',
        16: '默認gas費用 {0} ≈ {1} ( 最少預留約 {2})',
        17: '最小可得數量',
        18: '輸入最小可得數量，默認为0',
        19: '價格上漲',
        20: '賣出比例',
    },
    sale: {
        1: '购买失败则会无限重试，需小心谨慎',
        2: '下单完成后是否要检查本次购买是否成功，开启则需要多花几秒钟时间',
        3: '狂暴模式无视下单状态,需要设置提前下单秒数,燃烧gas请保留足够多的手续费',
        4: '狂暴模式',
        5: '選擇平台',
        6: '失敗重試',
        7: '購買/出售失敗則會無限重試，需小心謹慎',
        8: '狂暴模式',
        9: '狂暴模式無視下單狀態,需要設置提前下單秒數,燃燒gas請保留足夠多的手續費',
        10: '狂暴次數',
        11: '發起狂暴下單總次數',
        12: '提前下單 (秒)',
        13: '在開始前提前進行下單',
        14: '間隔時間 (毫秒)',
        15: '每次狂暴下單間隔時間',
        16: '',
        17: '',
        18: '',
        19: '',
        20: '',
    },
    order: {
        1: '輪訓間隔（毫秒）',
        2: '價格監控輪訓時間,根據自己網絡調整',
        3: '交易滑點最大百分比',
        4: '默認20%，建議必填，避免滑點過大造成損失',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
        13: '',
        14: '',
        15: '',
        16: '',
        17: '',
        18: '',
        19: '',
        20: '',
    },
    tool: {
        1: 'Vip尊享功能',
        2: '科学家抢购模式',
        3: '抢预售',
        4: '挂单模式',
        5: '多链批量打币',
        6: '批量生产钱包',
        7: '最新汽油费',
        8: '购买VIP',
        9: '选择公链',
        10: '选择交易所',
        11: '节点信息',
        12: '速度',
        13: '切換節點',
        14: '請輸入',
        15: '加载',
        16: '钱包地址',
        17: '请载入私钥获取地址',
        18: '载入私钥',
        19: '加載私鑰',
        20: '本程序完全本地运行,不会上传私钥到服务器,欢迎监督检测. ',
        21: '余额',
        22: '到期时间：',
        23: '强烈推荐使用小额资产的小钱包进行抢币，需要私钥是为了省去確認操作，直接实现快速自动买卖',
        24: '買入',
        25: '卖出',
        26: '交易币种',
        27: '流动池',
        28: '已自动识别',
        29: '流动池',
        30: '合约地址',
        31: '請輸入合约地址',
        32: '確認',
        3232: '提前授权',
        33: '數量',
        34: '請輸入交易數量',
        35: '次數',
        36: '請輸入{0}次數，默認1次',
        37: '挂单价格',
        38: '請輸入挂单价格',
        39: '设置为市价',
        40: '当前价格',
        41: '請輸入合约信息',
        42: '例如:您设置交易数量为2{0}，买入次数为5次，则科学家会自动连续购买2{0}，买5次，-共买10{0}',
        422: '設置買入金額為2{0}，買入次數為5次，則科學家會自動連續購買2{0}，買5次,一共買10{0}',
        43: '抢购速度',
        44: '默認Gas費用 {0}倍 ≈ {1} ( 最少預留約 {2} ) ',
        45: '失败重试',
        46: '购买/出售失败则会无限重试，需小心谨慎',
        47: '轮训间隔（毫秒）',
        48: '价格监控轮训时间,根据自己网络调整',
        49: 'Hash检查',
        50: '下单完成后是否要检查本次购买是否成功，开启则需要多花几秒钟时间',
        51: '高级功能',
        52: '燃烧最大百分比',
        53: '燃烧最大百分比默认20%， 建议必填，避免滑点过大造成损失',
        1152: '滑点最大百分比',
        1153: '滑点最大百分比默认20%， 建议必填，避免滑点过大造成损失',
        54: '立即執行',
        55: '暂停抢购',
        56: '抢购中',
        57: '請輸入私钥导入钱包',
        58: '仅限VIP使用',
        59: '請輸入交易数量',
        60: '請輸入代币合约地址后点击确定按钮',
        61: '請輸入挂单价格',
        62: '交易成功',
        63: '授权中...',
        64: '已授权',
        65: '请连接BSC链钱包购买VIP',
        66: '請輸入节点地址',
        67: '無效的 JSON RPC 響應',
        68: '永久会员',
        69: '未购买会员',
        70: '交易发出后几秒内未成交就交易失败,建议设置区间20-60',
        71: "下单前进行延迟一段时间,防止下单快的情况",
        72: "流动池实时监控,根据自己网络调整.",
        73: "烧Gas模式无限烧，需要最够的手续费",
    },
    vip: {
        1: '加入VIP',
        2: '天',
        3: '预计支付:',
        4: '立即搶購',
        5: '余额：',
        6: '请连接BSC链钱包购买VIP',
        7: '请选择要购买的会员等级',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
        13: '',
    },
    lang: {
       
        5:'语言',
        fail: '领取失败',
        success: '领取成功',

        fail1: '失败',
        success1: '成功',
       
        err1: '已绑定上级',
        err2: '已有下级，不能再绑定上级',
        err22: '已经质押过，不能绑定上级',
        err3: '请设置上级地址',
        err4: '不能绑定自己',
        confirm: '確認绑定',
        buy: '已购买',
        input: '输入地址绑定关系',
        nodata: '暂未有下级购买',
        nodata2: '暂未有地址参与',

        pause: '未开始',
        balanceNotEnough: '余额不足',

        a1: '暂停购买',
		a2: '已参入',
		a3: '余额不足',
		a4: '授权失败',
        a5: '一期还未结束',
        notStart: "未开始",
        remainAmount: '预售额度不足',
        min: '最少购买',
        max: '最多购买',

    },
    buy: {
        1: '流动池最小数量（USDT）',
        2: '不填写默认0，不进行检测',
        3: '金额',
        4: '請輸入买入金额',
        5: '次数',
        6: '监控(毫秒)',
        7: '买入失效(秒)',
        8: '延迟下单(毫秒)',
        9: '燃Gas模式',
        10: '最小可得数量',
        11: "請輸入最小可得数量，默认为0",
        12: "例子:假设币的开盘价格是1U，您使用10U去购买10个币，如果最小数量填写15个，会因为购买数量达不到最小数量，则交易回滚",
        13: "价格上涨",
        14: "价格上涨，默认0%",
        15: "卖出比例",
        16: "卖出比例，默认0%",
        17: "间隔时间(毫秒)",
    },
    
}